import React from "react"
import styled from "styled-components"


const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  text-align: left;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 1rem;
  ul {
    padding-left: 0;
    width: 100%;
  }
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
  }
  .price {
    min-width: max-content;
    margin-left: 20px;
  }
  .details {
    width: 100%;
  }
  
`

const RatesTable = ({
  title,
  details,
  id = null,
}) => {
  return (
    <Wrapper id={id}>
      <h4>{title}</h4>
      <div className="details" dangerouslySetInnerHTML={{
          __html: details,
        }} />
    </Wrapper>
  )
}

export default RatesTable