import React from "react"
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from "react-accessible-accordion"
import styled from "styled-components"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"

const Styled = styled.div`
  ul {
    padding-inline-start: 10px;
    li {
      list-style: none;
      position: relative;
      &:before {
        content: "-";
        position: absolute;
        left: -10px;
      }
    }    
  }
  
`

const RatesAccordion = ({
  title,
  text,
  id = null,
}) => {
  return (
    <Styled>
      <AccordionItem>
        <AccordionItemHeading>
            <AccordionItemButton>
              <h4>{title}</h4>
            </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
        {/* <div dangerouslySetInnerHTML={{
          __html: details,
        }} /> */}

          {documentToReactComponents(text)}
        </AccordionItemPanel>
      </AccordionItem>
    </Styled>
    
  )
}

export default RatesAccordion