import React from "react"
import { PageLayout } from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import TextSectionBackground from "../components/TextSectionBackground"
import { ActionButton } from "../components/buttons"
import EnquireButton from "../components/EnquireButton"
import { PageHeader } from "../components/Headings 1"
import styled from "styled-components"
import SEO from "../components/SEO"
import RatesTable from "../components/RatesTable"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import RatesAccordion from "../components/RatesAccordion"
import ImageGrid from "../components/ImageGrid"
import ImageSlider from "../components/ImageSlider"

const RatesWrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  flex-wrap: wrap;
  p,
  h3,
  h4,
  li {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
  }
  p,
  li {
    color: #363636;
    a {
      color: #363636;
      font-weight: 600;
      text-decoration: none;
    }
  }
  h3 {
    font-size: 26px;
    font-weight: 200;
  }
  p,
  h4 {
    text-align: left;
  }
  li {
    margin-bottom: 0.5em;
  }
  .text-center {
    text-align: center;
  }
  .disclaimer {
    margin-top: 3rem;
  }
`

const ColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  @media all and (max-width: 1080px) {
    flex-direction: column;
  }
`

const Column = styled.div`
  width: 25%;
  padding: 0 10px;
  min-width: 280px;
  @media all and (max-width: 1080px) {
    width: 100%;
    max-width: 550px;
    margin-bottom: 2rem;
    h3 {
      text-align: left;
    }
  }
`

const ButtonWrapper = styled.div`
  text-align: left;
  margin: 3rem 0 2%;
  button {
    margin: 8px 10px;
  }
`

const ImageWrapper = styled.div`
  margin: 4% 0 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  > div {
    width: 45%;
    min-width: 300px;
    @media (max-width: 880px) {
      width: 100%;
      margin: 20px 0;
    }
  }
`

const Accommodation = () => {
  const {
    contentfulRatesPage: { rate, rateExtra, ratesPolicy, images },
  } = useStaticQuery(graphql`
    {
      contentfulRatesPage {
        rate {
          title
          details {
            details
          }
        }
        rateExtra {
          title
          details {
            details
          }
        }
        ratesPolicy {
          title
          details {
            json
          }
        }
        images {
          id
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <PageLayout view={true}>
      <SEO
        title="Mavela Lodge Rates"
        description="Please contact us directly at reservations@mavela.Co.Za for the best possible rate, or to book the lodge for exclusive use."
      />
      <TextSectionBackground pa="3rem 5%">
        <PageHeader>Mavela Lodge Rates</PageHeader>
        <RatesWrapper>
          <p className="text-center">
            Please contact us directly at{" "}
            <a href="mailto:reservations@mavela.co.za">
              reservations@mavela.co.za
            </a>{" "}
            for the best possible rate, or to book the lodge for exclusive use.
          </p>
          <ColumnWrapper>
            <Column>
              <h3>Rack Rates</h3>
              {rate.map((rate: any, i: number) => (
                <RatesTable
                  title={rate.title}
                  details={rate.details.details}
                ></RatesTable>
              ))}
              <p className="disclaimer">
                **Rates are subject to change at the discretion of management.
              </p>
            </Column>

            <Column>
              <h3>Extras (2024)</h3>
              {rateExtra.map((rateExtra: any, i: number) => (
                <RatesTable
                  title={rateExtra.title}
                  details={rateExtra.details.details}
                ></RatesTable>
              ))}
              <p className="disclaimer">
                **All extras are subject to availability. Please enquire via
                reservations.
              </p>
            </Column>

            <Column>
              <h3>Experience the Mavela Magic!</h3>
              <Accordion
                allowZeroExpanded="boolean"
                allowMultipleExpanded="boolean"
              >
                {ratesPolicy.map((ratesPolicy: any, i: number) => (
                  <RatesAccordion
                    title={ratesPolicy.title}
                    text={ratesPolicy.details.json}
                  ></RatesAccordion>
                ))}
              </Accordion>
            </Column>
          </ColumnWrapper>

          <ButtonWrapper>
            <EnquireButton />
            <ActionButton>
              <a
                href="https://www.nightsbridge.co.za/bridge/book?bbid=16200"
                rel="noopener noreferrer"
                target="_blank"
              >
                Check Availability
              </a>
            </ActionButton>
            <ActionButton>
              <a href="/special-rates" rel="noopener noreferrer" target="self">
                Specials
              </a>
            </ActionButton>
          </ButtonWrapper>

          <ImageWrapper>
            {images.map((image, i) => (
              <div>
                <Img fluid={image.fluid}></Img>
              </div>
            ))}
          </ImageWrapper>
        </RatesWrapper>
      </TextSectionBackground>
    </PageLayout>
  )
}

export default Accommodation
